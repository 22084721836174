<template>
  <div id="bio-data">
    <b-form-group id="input-group-1" label-for="input">
      <template v-slot:label>
        Name <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="input-1"
        v-model="addAgentForm.va_name"
        type="text"
        required
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group id="input-group-2" label-for="input-2">
          <template v-slot:label>
            Date of Birth <span class="text-danger">*</span>
          </template>
          <b-form-datepicker v-model="addAgentForm.va_dob" type="text" required>
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-3" label-for="input-3">
          <template v-slot:label>
            Gender <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="input-3"
            v-model="addAgentForm.va_gender"
            :options="gender"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group id="input-group-4" label-for="input-4">
      <template v-slot:label>
        Phone Number <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="input-4"
        type="tel"
        v-model="addAgentForm.va_phonenumber"
        placeholder="e.g 0702000888"
        pattern="^07[0-9]{8}"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-7" label-for="input-7">
      <template v-slot:label>
        Region <span class="text-danger">*</span>
      </template>
      <b-form-select
        id="input-7"
        v-model="addAgentForm.va_region"
        :options="regions"
        required
      ></b-form-select>
    </b-form-group>

    <vue-districts :districts="districts" v-model="addAgentForm.va_district"></vue-districts>

    <b-form-group id="input-group-6" label="Village" label-for="input-6">
      <template v-slot:label>
        Village <span class="text-danger">*</span>
      </template>
      <b-form-input
        id="input-6"
        type="text"
        v-model="addAgentForm.va_village"
        required
      ></b-form-input>
    </b-form-group>
  </div>
</template>
<script>
import VueDistricts from '../../VueDistricts.vue';
export default {
  components:{VueDistricts},
  props: {
    addAgentForm: {
    type: Object,
      default: () => {
        return {};
      },
    },
    districts: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      k: this.tab,
      gender: [{ text: "Select Gender", value: null }, "Male", "Female"],
      unknown_district:"",
      regions: [
        { text: "Select Region", value: null },
        "East",
        "West",
        "North",
        "Central",
      ],
    };
  },
};
</script>
<style scoped>
.unknown_ds{
  font-size: 0.80em;
}
</style>
