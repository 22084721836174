<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">AGENTS</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button squared variant="primary" v-b-modal.add-agent 
        v-analytics:click="['add_new_agent',{element:'Suppliers'}]"
        :disabled="!can('modify','suppliers')">
          <b-icon-plus></b-icon-plus> New Agent
        </b-button>
        &nbsp;
        <b-button squared variant="primary" v-b-modal.agent-upload 

        :disabled="!can('modify','suppliers')">
          <b-icon-file-arrow-up></b-icon-file-arrow-up> Agent Upload
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <b-row class="m-0">
        <b-col>
          <b-select
            class="form-select mr-2"
            v-model="chosenDistrict"
            @change="filterByDistrict"
            v-analytics:change="['filter_agent_district',{element:'Suppliers'}]"
            :options="districts"
          >
            <template #first>
              <b-select-option selected value="All Districts"
                >All Districts</b-select-option
              >
            </template>
          </b-select>
          <b-form class="d-inline-block">
            <b-form-input
              placeholder="Search Name"
              type="search"
              class="search"
              v-model="searchfield"
              v-analytics:change="['search_agent',{element:'Suppliers'}]"

            ></b-form-input>
          </b-form>
        </b-col>

        <div class="col-auto text-right">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload" v-analytics:click="['refresh_agent_page',{element:'Suppliers'}]">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" @click="print" v-analytics:click="['print_agents',{element:'Suppliers'}]">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <button class="btn-opt primary" @click="export_csv" v-analytics:click="['export_agents',{element:'Suppliers'}]">
              <b-icon-book></b-icon-book> Export
            </button>
          </div>
        </div>
      </b-row>
    </div>

    <div class="container-fluid" ref="printable">
      <div class="text-center" v-if="isLoadingAgents && !isAdding">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <div v-if="!isLoadingAgents || isAdding" class="text-capitalize">
        <div v-if="!filteredAgentsData.length" class="text-center">
          No agents added yet...
        </div>
        <div v-if="filteredAgentsData.length" class="text-capitalize">
          <b-table
            :fields="fields"
            :items="filteredAgentsData"
            :per-page="perPage"
            :filter="searchfield"
            :current-page="currentPage"
            thead-class="bg-primary-tb text-primary-tb border-primary-tb"
            @row-clicked="getAgentId"
          >
            <template #cell(va_name)="data">
              <b-link @click="getAgentId(data.item)">
                {{ data.item.va_name }}</b-link
              >
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="agt-tab"
            class="b-pagination-class"
          ></b-pagination>
        </div>
      </div>
    </div>

    <add-agent
      @AddAgentSuccess="onAgentAdded"
      :districts-data="this.districts"
    ></add-agent>
    <agent-upload></agent-upload>
  </div>
</template>

<script>
import AddAgent from "../../../../components/add_agent/AddAgent.vue";
import { axiosApiInstance } from "@/plugins/axios";
import AgentUpload from "./AgentUpload.vue";
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import moment from "moment";
import { can } from '../../../auth/utils';
export default {
  components: { AddAgent, AgentUpload },
  data() {
    let fields = [
      { key: "va_name", label: "Name" },
      { key: "va_phonenumber", label: "Contact" },
      { key: "va_district", label: "District" },
      { key: "farmers", label: "Farmers" },
      {
        key: "commission",
        label: "commission",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "total acreage",
        label: "total acreage",
        thClass: "d-none",
        tdClass: "d-none",
      },
    ];
    return {
      searchfield: "",
      fields,
      message: "",
      error: "",
      agentsData: [],
      isLoadingAgents: false,
      isAdding: false,
      filteredAgentsData: [],
      districts: [],
      chosenDistrict: "All Districts",
      filterData: [],
      perPage: 10,
      currentPage: 1,
    };
  },

  mounted: function () {
    this.getAgentsPerProject(this);
    this.setDistrictsToSelect();
    this.$analytics.track("open_agents", { element: "Agents" });
  },

  computed: {
    rows() {
      return this.filteredAgentsData.length;
    },

    projectId() {
      return this.$store.state.project_id;
    },
  },

  methods: {
    can,
    reload(){
      this.$router.go(this.$router.currentRoute)
    },
    print(){
      const elm = this.$refs.printable;
      const doc = new jsPDF('landscape','mm',[360,480]);
      html2canvas(elm).then((canvas)=>{
        const img = canvas.toDataURL("image/jpeg");
        doc.addImage(img,'JPEG',20,20);
        const today= moment().format("YYYY-MM-DD");
        doc.save("Agents--"+today+".pdf");
      })
    },
    export_csv(){
      let rows = ["va_name","va_phonenumber","va_district","farmers","commission","total acreage"];
      let items = this.agentsData.map(el=>[
        el["va_name"],el["va_phonenumber"],el["va_district"],el["farmers"],
        el["commssion"]?el["commission"]:"" ,el["total acreage"]?el["total_acreage"]:""
      ]);
      let data = [rows,...items];
      let csvContent = "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "agents_data.csv");
      document.body.appendChild(link); // Required for FF
      link.click(); 
    },
    async getAgents(){
      this.isLoadingAgents = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      const res = await axiosApiInstance({url:baseUrl+"/api/v3/village-agents/"})
      const data = res.data.data;
      this.agentsData = data;
      this.filterData = data;
      this.filteredAgentsData = data;
      this.isLoadingAgents = false;
      this.isAdding = false;
    },
    async getAgentsPerProject(dataObj) {
      this.isLoadingAgents = true;
      const baseUrl = process.env.VUE_APP_BACKEND;
      try {
        const result = await axiosApiInstance({
          url: baseUrl + `/api/v3/village-agents/${this.projectId}`,
        });
        this.isLoadingAgents = false;
        if (result.data.success) {
          dataObj.agentsData = result.data.data;
          dataObj.filteredAgentsData = result.data.data;
          dataObj.filterData = result.data.data;
        }
        this.isAdding = false;
      } catch (error) {
        this.isLoadingAgents = false;
        this.error = error;
      }
    },

    onAgentAdded(status) {
      this.isAdding = status;
      this.getAgentsPerProject(this);
    },

    getAgentId(item) {
      const vaId = item.vaId.replace(/\//g, "-");
      this.$router.push(`/suppliers/agent-profile/${vaId}`);
    },

    setDistrictsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;
      axiosApiInstance
        .get(baseUrl + `/api/v3/farmers/districts`)
        .then((result) => {
          if (result.data.success) {
            const res = result.data.data.sort((a, b) =>
              a.district.localeCompare(b.district)
            );
            res.map((each) => {
              this.districts.push(each.district);
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
    searchAgents() {
      if (this.searchfield.length !== 0 && this.filterData) {
        this.filteredAgentsData = this.filterData.filter((item) =>
          item["va_name"].toLowerCase().includes(this.searchfield.toLowerCase())
        );
      } else {
        this.filteredAgentsData = this.filterData;
      }
      // (this.searchfield.length !== 0 && this.filterData) ?
      // this.filteredAgentsData = this.filterData.filter(
      //   data => data.va_name.toLowerCase()
      //   .indexOf(this.searchfield.toLowerCase())!== -1) :
      // this.filteredAgentsData = this.filteredItems;
    },

    filterByDistrict() {
      let agentsData = [];

      if (this.chosenDistrict === "All Districts") {
        agentsData = this.agentsData;
      } else {
        this.agentsData
          ? this.agentsData.map((each) => {
              this.chosenDistrict === each.va_district
                ? agentsData.push(each)
                : null;
            })
          : null;
      }
      this.filterData = agentsData;
      this.filteredAgentsData = agentsData;
      this.searchfield = "";
    },
  },
};
</script>

<style>
.pagination.b-pagination-class {
  margin-top: 40px;
}
</style>
