import { axiosApiInstance } from "@/plugins/axios";
function add_agent() {
    let data = new FormData();
    let form=  this.form;

    for (var key in form) {
        data.append(key, form[key]);
    }
    axiosApiInstance({
        url: process.env.VUE_APP_BACKEND + "/add_agent",
        method: 'post',
        data: data
    }).then(res=>{
        
       console.log(res.status);
    }).catch(error=>{
        console.log(error);
    });
    // axios.get
}
export {add_agent}