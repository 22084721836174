<template>
  <div id="services">
    <h5>3. Services to farmers</h5>
    <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        v-model="addAgentForm.services_va_provides"
        :options="services"
        id="checkboxes-4"
        :aria-describedby="ariaDescribedby"
      >
      </b-form-checkbox-group>
    </b-form-group>
    <b-input-group class="my-1" v-show="addAgentForm.services_va_provides.includes('Other')">
      <b-form-input v-model="other" type="text" placeholder="please specify other"></b-form-input>
      <b-input-group-append>
        <b-button variant="outline-success" :disabled="other.length == 0" @click="add_option(addAgentForm)">add service</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script>
export default {
  props: {
    addAgentForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    this.addAgentForm;

    return {
      other: "",
      services: ["ProduceBulking", "Profiling", "Order Inputs", "Other"],
    };
  },
  methods: {
    /**
     * @param {{services_va_provides:Array<string>}} addAg
     */
    add_option(addAg) {
      this.services.push(this.other);
      addAg.services_va_provides.push(this.other);
      addAg.services_va_provides = addAg.services_va_provides.filter(sv=>sv!='Other');
    },
  },
};
</script>
