<template>

    <b-form-group id="input-group-5" label-for="input-5">
      <template v-slot:label>
        District <span class="text-danger">*</span>
      </template>
      <!-- <vue-bootstrap-typeahead
        :data="districts"
        v-model="addAgentForm.va_district"
        placeholder="Type district..."
        @change="validate_district"
      ></vue-bootstrap-typeahead> -->
      <b-form-input list="districts" required @change="validate" v-model="district"></b-form-input>
      <datalist id="districts">
        <option :value="district" v-for="district in districts" :key="district"/>
      </datalist>
      <span v-show="unknown_district" class="text-danger unknown_ds"><i>No district by name {{unknown_district}}</i></span>
    </b-form-group>
</template>
<style scoped>
.unknown_ds{
    font-size: 0.85em;
}
</style>
<script>
export default {
    props:{
        districts:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        return {
            unknown_district:"",
            district:""
        }
    },
    methods:{
        validate(){ 
            if(this.district){
                if(!this.districts.includes(this.district)){
                    this.unknown_district = this.district;
                    this.district="";
                }else{
                    this.unknown_district="";
                    this.$emit("input",this.district);
                }
            }
        }
    }
}
</script>