<template>
  <div>
    <h6 class="text-center">{{ notify.title() }}</h6>
    <b-progress :max="100" height="2rem" :variant="notify.variant()">
      <b-progress-bar :value="100" :animated="notify.status == 'processing'">
      </b-progress-bar>
    </b-progress>
    <p class="text-center">{{ notify.message }}</p>
    <b-button variant="outline-primary" @click="$emit('clear-file')" class="w-100 mt-2" :disabled="notify.status=='processing'"
      >{{notify.btn_text()}}</b-button
    >
  </div>
</template>
<script>
import { Notification } from "./upload";
export default {
  props: {
    notify: {
      type: Notification,
    },
  },
};
</script>