<template>
  <div id="identity" >
    <h5>2. Identity</h5>
    <b-form-group id="input-group-8" label="Agent Photo" label-for="input-8">
      <input type="file" id="file1" ref="file1" v-on:change="handleFileUpload()"
      accept="image/*"
      />
    </b-form-group>

    <b-form-group id="input-group-9" label="National ID Image" label-for="n-id-image">
      <input type="file" id="file2" ref="file2" v-on:change="handleFileUpload()"
      accept="image/*"
      />
    </b-form-group>

    <b-form-group id="input-group-10" label="National ID Number" label-for="input-10">
      <b-form-input
        id="input-10"
        v-model="addAgentForm.va_id_number"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    addAgentForm: {
      type: Object,
      default: () => {
        return {};
      },
    },

  },

  data() {
    return {
      file1: null,
      file2: null,
    }
  },

  methods: {
    handleFileUpload() {
      this.file1 = this.$refs.file1.files[0];
      this.file2 = this.$refs.file2.files[0];

      let files = {
        file1: this.file1,
        file2: this.file2
      }
      this.$emit('onFilesUploaded', files);
    },
  }
};
</script>
