/**
 * 
 * @param {number} project_id 
 */
function CSVFile() {
    this.fyl = null
    this.project_id = 0;

    this.is_empty = () => {
        return this.fyl == null;
    }
    this.as_form = () => {
        const formdata = new FormData();
        formdata.append("csv-file", this.fyl);
        formdata.set("project_id", this.project_id);
        return formdata;
    }
    /**
     * @param {File} fyl
     */
    this.set_file = (fyl)=>{
        this.fyl = fyl;
    }
    /**
     * 
     * @param {number} project_id 
     */
    this.set_project = (project_id)=>{
        this.project_id = project_id;
    }
    this.refresh = () => {
        this.fyl = null;
    }
}
/**
 * 
 * @param {string} status 
 * @param {string} message 
 */
function Notification(status, message) {
    this.variants = {
        processing: "primary",
        finished: "success",
        error: "danger"
    }
    this.btn_texts = {
        processing: "PLEASE WAIT",
        finished: "UPLOAD MORE",
        error: "TRY AGAIN"
    }
    this.status = status;
    this.message = message;
    this.variant = () => {
        return this.variants[this.status];
    }
    this.title = () => {
        return this.status[0].toUpperCase() + this.status.slice(1).toLowerCase();
    }
    this.btn_text = () => {
        return this.btn_texts[this.status];
    }
}
import axios from "axios";
import Vue from "vue";
const AJAX_DEFAULTS = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
};
const CsvUpload = Vue.mixin({
    computed: {
        projectId() {
            return this.$store.state.project_id;
        }
    },
    data() {
        return {
            notification: new Notification("unloaded", ""),
            csv: new CSVFile(),
        };
    },
    methods: {
        /**
         * @param {{target:{files:File[]}}} e
         */
        pick_file(e) {
            const fyl = e.target.files[0];
            this.csv.set_file(fyl);
            this.csv.set_project(this.projectId);
        },
        notifi(status, message) {
            this.notification = new Notification(status, message);
        },
        upload_file() {
            this.notifi("processing", "");
            axios({
                url:this.upload_url,
                method: "post",
                data: this.csv.as_form(),
                ...AJAX_DEFAULTS,
            })
                .then((res) => this.notifi("finished", res.data))
                .catch((err) => {
                    console.error(err);
                    this.notifi("error", err.response.data);
                });
        },
        clear_file() {
            this.notification = new Notification("unloaded", "");
            this.csv.refresh();
        },
        csv_template() {

            axios({
                url: this.csv_url,
                responseType: "blob",
                ...AJAX_DEFAULTS,
            }).then((res) => {
                const url = URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.csv_name); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
    },


});
export { CsvUpload, Notification }