<template>
  <b-modal id="add-agent" title="New  Agent" hide-footer>
    <div class="container-fluid">
      <b-form @reset.prevent="onReset">
        <!-- Bio Data -->
        <b-form
          @submit.prevent="tab = 'identity'"
          @reset.prevent="onReset"
          v-show="tab == 'biodata'"
        >
          <bio-data
            :addAgentForm="addAgentForm"
            :districts="districtsData"
          ></bio-data>
          <b-row>
            <b-col>
              <div class="float-right">
                <b-button type="reset" variant="secondary" v-analytics:click="['step_one_cancel_add_agent',{element:'Suppliers'}]">Cancel</b-button>
                <b-button type="submit" variant="primary" 
                v-analytics:click="['step_one_add_agent',{element:'Suppliers'}]"
                class="ml-2">
                  Next
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>

        <!-- Identity -->
        <b-form
          @submit.prevent="tab = 'services'"
          @reset.prevent="onReset"
          v-show="tab == 'identity'"
        >
          <identity :addAgentForm="addAgentForm" @onFilesUploaded="onUpload">
          </identity>
          <b-row>
            <b-col>
              <b-button variant="primary" @click="tab = 'biodata'"
                v-analytics:click="['backward_add_agent',{element:'Suppliers'}]"
                >Back</b-button
              >
            </b-col>
            <b-col>
              <div class="float-right">
                <b-button type="reset" variant="secondary" v-anlaytics:click="['step_two_cancel_agent',{element:'Suppliers'}]">Cancel</b-button>
                <b-button type="submit" 
                v-analytics:click="['step_two_add_agent',{element:'Suppliers'}]"
                variant="primary" class="ml-2">
                  Next
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>

        <!-- Services -->
        <b-form
          @submit.prevent="addAgentPerProject"
          @reset.prevent="onReset"
          v-show="tab == 'services'"
        >
          <services :addAgentForm="addAgentForm"></services>
          <b-row>
            <b-col>
              <b-button variant="primary" @click="tab = 'identity'"
                v-analytics:click="['step_three_backward_add_agent',{element:'Suppliers'}]"
                >Back</b-button
              >
            </b-col>
            <b-col>
              <div class="float-right">
                <b-button type="reset" variant="secondary"
                v-analytics:click="['step_three_cancel_agent',{element:'Suppliers'}]"
                >Cancel</b-button>
                <b-button type="submit" variant="primary" class="ml-2" :disabled="isAdding">
                  <b-spinner
                    v-show="isAdding"
                    small
                    label="Small Spinner"
                    v-analytics:click="['save_new_agent',{element:'Suppliers'}]"
                  ></b-spinner>
                  Submit
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { add_agent } from "./actions.js";
import BioData from "./subcomponents/Biodata.vue";
import Services from "./subcomponents/Services.vue";
import Identity from "./subcomponents/Identity.vue";
import { axiosApiInstance } from "@/plugins/axios";

export default {
  components: { BioData, Services, Identity },
  data() {
    return {
      message: "",
      error: "",
      isAdding: false,
      addAgentForm: {
        va_name: "",
        va_dob: "",
        va_gender: null,
        va_phonenumber: "",
        va_district: null,
        va_village: "",
        va_region: "",
        va_id_number: "",
        services_va_provides: [],
      },
      tab: "biodata",
      gender: [{ text: "Select One", value: null }, "male", "female"],
      show: true,
      file1: null,
      file2: null,
    };
  },

  props: {
    districtsData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },

  methods: {
    onReset() {
      this.addAgentForm.va_name = "";
      this.addAgentForm.va_dob = "";
      this.addAgentForm.va_gender = null;
      this.addAgentForm.va_phonenumber = "";
      this.addAgentForm.va_district = null;
      this.addAgentForm.va_village = "";
      this.addAgentForm.va_region = "";
      this.addAgentForm.services_va_provides = [];
      this.addAgentForm.va_id_number = "";
      this.file1 = null;
      this.file2 = null;
      this.$bvModal.hide("add-agent");
      this.tab = "biodata";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    addAgentPerProject() {
      this.isAdding = true;
      var self = this;
      const baseUrl = process.env.VUE_APP_BACKEND;

      let formData = new FormData();
      if (this.file1) {
        formData.append("va_photo", this.file1, this.file1.name);
      }
      if (this.file2) {
        formData.append("va_id_photo", this.file2, this.file2.name);
      }

      formData.append("data", JSON.stringify(this.addAgentForm));

      axiosApiInstance
        .post(
          baseUrl + `/api/v3/village-agents/Uganda/${this.projectId}`,
          formData
        )
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            self.$vToastify.success(this.message);
            this.isAdding = false;
            this.onReset();
            this.$emit("AddAgentSuccess", this.isAdding);
          } else if (!result.data.success) {
            this.isAdding = false;
            this.error = result.data.message;
            // alert(this.error);
            self.$vToastify.error(this.error);
          }
        })
        .catch((error) => {
          this.isAdding = false;
          if (error.response.status == 409) {
            this.addExistingAgent(error.response.data.data);
          } 
          else if(error.response.status == 408){
            this.isAdding = false;
            const data = error.response.data;
            this.$dialog.alert({
              title:data['message'],
              body:data['reason']
            });
          }
          else if(error.response.status == 422){
            this.isAdding = false;
            const data = error.response.data;
            this.$dialog.alert({
              title:"Form Error",
              body:data['errors'][0]['message']
            });
          }else if(error.response.status == 423){
            this.isAdding = false;
            const message = error.response.data['message'];
            this.$dialog.alert({
              title:'Action Not Supported',
              body:message
            });
          }else {
            this.isAdding = false;
            this.error = error;
            // alert(this.error);
            self.$vToastify.error("Something went wrong please try again");
          }
        });
    },
    /**
     * @param {object} data
     */
    addExistingAgent(data) {
      try {
        const msg = `${data.va_name} is already registered in another project.
            Do you wish to have this farmer registered in this project too ?
            `;
        const va = data;
        if (va.projects.includes(parseInt(this.projectId))) {
          throw {msg:`${va.va_name} is already registered in project.`,type:'Resource Conflict'};
        }
        this.$dialog.confirm({
          title:"Agent Exists",
          body:msg
          }).then(()=>{
          const info = new FormData();
          info.set("project_id", parseInt(this.projectId));
          let vaId = data.vaId.replaceAll("/","-");
          // alert(vaId);
          axiosApiInstance
            .patch(
              process.env.VUE_APP_BACKEND +
                `/api/v3/village-agents/${vaId}/:add_project`,
              info
            )
            .then((result) => {
              this.isAdding = false;
              this.message = result.data;
              this.onReset();
              this.$emit("AddAgentSuccess", this.isAdding);

            });
        })
      } catch (err) {
        this.$dialog.alert({
          title:err.type,
          body:err.msg
        }).then(()=>{
          this.onReset();
        })
      }
    },

    onUpload(data) {
      this.file1 = data.file1;
      this.file2 = data.file2;
    },

    add_agent,
  },
};
</script>

<style>
.height-20 {
  max-height: 20vh;
}
</style>
